import React from "react";
import 'bulma/css/bulma.min.css';
import { Heading, Container, Content, Section } from "react-bulma-components";
import { Link } from "gatsby"

export { Head } from "./header";

const Footer = () => (
  <div>
    <Section className="narrow-section">
      <Container>
        <Heading renderAs="h3">Links</Heading>
        <Content>
          <Link className="underline-blue is-size-5" to='/'>Home</Link>
        </Content>
        <Content>
          <Link className="underline-blue is-size-5" to='/terms'>Terms</Link>
        </Content>
        <Content>
          <Link className="underline-blue is-size-5" to='/refunds'>Refunds</Link>
        </Content>
        <Content>
          <Link className="underline-blue is-size-5" to='/pricing'>Pricing</Link>
        </Content>
      </Container>
    </Section>

    <Section className="narrow-section">
      <Container>
        <Content>
          <p className="black-text">
            &#169; COPYRIGHT Make Chrome Text Black {new Date().getFullYear()}
          </p>
        </Content>
      </Container>
    </Section>
  </div>
);

export default Footer;
