import React from "react";
// import Head from "./head";
import 'bulma/css/bulma.min.css';
import { Container, Section } from "react-bulma-components";
import { Link } from "gatsby"
import SEO from "./seo.jsx"


const Header = () => {

  return (
    <div>
      {/* <Head /> */}
      <Section className="section-padding-top narrow-section is-hidden-mobile">
        <Container>
          <p className="is-size-4" style={{ color: "black", fontWeight: "700" }}>
            <Link className="underline-blue" to="/">Make Chrome Text Black</Link>
          </p>
        </Container>
      </Section>

      <Section className="narrow-section is-hidden-tablet" style={{ paddingBottom: "0" }}>
        <Container>
          <p className="is-size-4" style={{ color: "black", fontWeight: "700" }}>
            <Link className="underline-blue" to="/">Make Chrome Text Black</Link>
          </p>
        </Container>
      </Section>
    </div >
  );
};

export default Header;

export function Head() {
  return (
    <>
      <div className="application">
        <meta charSet="utf-8" />
        <title>Make Chrome Text Black</title>
        <link rel="canonical" href="https://makechrometextblack.com" />

        <link
          href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,700&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Merriweather:300,400,700&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Saira+Stencil+One&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Inconsolata:wght@300;400;500;600;700;800;900&display=swap"
          rel="stylesheet"
        />
      </div>
      < SEO />
    </>
  )
}
